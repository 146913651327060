<template>
  <div class="app-footer">
    <div class="footer-top container">
      <el-row :gutter="60">
        <el-col :span="10">
          <h2>FIT2CLOUD 飞致云</h2>
          <p>
            我们秉持“软件用起来才有价值，才有改进的机会”的核心价值观，向中国数字化团队交付被广泛验证、可信赖的通用工具软件。
          </p>
          <p></p>
          <!-- <h2 style="font-size: 17px; text-align: center">- 旗舰产品 -</h2>
          <div class="foot-card">
            <a href="https://fit2cloud.com/cmp/index.html" target="_blank">
              <img src="https://fit2cloud.com/images/logo/footer-logo-20-CloudExplorer.svg"
                width="120px" alt="MeterSphere Logo">
              <p>多云管理平台</p>
            </a>
          </div> -->
        </el-col>
        <!-- <el-col :span="4">
          <h2>快速浏览</h2>
          <ul>
            <li>
              <a href="./about/index.html">
                <i class="el-icon-arrow-right"></i>关于我们</a>
            </li>
            <li>
              <a href="./customers/index.html">
                <i class="el-icon-arrow-right"></i>案例中心</a>
            </li>
            <li>
              <a href="https://blog.fit2cloud.com/">
                <i class="el-icon-arrow-right"></i>技术博客</a>
            </li>
            <li>
              <a href="./purchase/index.html">
                <i class="el-icon-arrow-right"></i>如何购买</a>
            </li>

          </ul>
        </el-col> -->
        <el-col :span="7">
          <h2>联系我们</h2>
          <ul>
            <li>
              <i class="icon el-icon-message"></i><a>support@fit2cloud.com</a>
            </li>
            <li><i class="icon el-icon-phone-outline"></i>400-052-0755</li>
            <!-- <li>
              <a target="_blank" href="https://jinshuju.net/f/UFPJsq" class="footer-text-color">
                <i class="el-icon-thumb"></i>预约产品演示</a>
            </li> -->
          </ul>
        </el-col>
        <el-col :span="6">
          <h2>关注我们</h2>
          <img
            src="https://www.fit2cloud.com/images/contact/f2c.png"
            width="150px"
            alt=""
          />
        </el-col>
      </el-row>
      <!-- <h2>开源产品</h2> -->
    </div>

    <p>浙ICP备14038283号</p>
    <p>2014-2025 版权所有©杭州飞致云信息科技有限公司</p>
    <p>
      <a
        href="https://www.fit2cloud.com/legal/tos.html"
        class="text-foot title-p"
        >使用条款 |
      </a>
      <a
        href="https://www.fit2cloud.com/legal/privacy-policy.html"
        class="text-foot title-p"
        >隐私政策 |
      </a>
      <a
        href="https://www.fit2cloud.com/legal/licenses.html"
        class="text-foot title-p"
        >社区软件许可协议</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped lang="scss">
.app-footer {
  background: #202942;
  color: #adb5bd;
  flex: 0 0 auto;
  text-align: center;
  padding: 20px 0;
  z-index: 1;
  .footer-top {
    border-bottom: 1px solid #3d4349;
    line-height: 30px;
    padding-bottom: 30px;
    i {
      margin-right: 5px;
    }
    h2 {
      color: #ffffff;
    }
    .el-col {
      text-align: left;
    }
  }
  .foot-card {
    background-color: #fff;
    text-align: center;
    line-height: 20px;
    padding: 10px 0;
    p {
      padding: 0;
      margin: 0;
    }
  }
}
</style>
