<template>
  <div class="header-wrapper" :style="style" :class="{ color: color }">
    <app-nav class="app-nav" />
  </div>
</template>
<script>
import AppNav from "./AppNav";
export default {
  name: "AppHeader",
  components: { AppNav },

  watch: {},
  data() {
    return {
      style: {
        backgroundColor: "",
      },
      color: false,
    };
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //设置背景颜色的透明读
      if (scrollTop) {
        this.style.backgroundColor = `rgba(31, 41, 66,${
          scrollTop / (scrollTop + 80)
        })`;
        this.color = true;
      } else if (scrollTop == 0) {
        this.style.backgroundColor = "transparent";
        this.color = false;
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-wrapper {
  position: relative;
  .app-nav {
    position: absolute;
    z-index: 999;
    right: 0;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  // border: 0;
  // transition: all 0.5s ease;
  // color: #ffffff;
}
// .color {
  // box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
// }
</style>

