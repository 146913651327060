import message from "@/plugins/message";
import request from "@/plugins/request";
import keycloak from "@/plugins/keycloak";
export default {
  install(Vue) {
    Vue.use(message);
    Vue.use(request);
    Vue.use(keycloak);
  }
}
