<template>
  <div class="home-banner">
    <div class="home-carousel">
      <transition-group tag='div' name='img'>
        <div class="carousel-item" v-for="(item,index) in carouselList" :key="index"
          v-show='index===mark'
          :style="item.image && {backgroundImage: `url(${item.image})`}">
          <!-- <div class="bg-overlay"></div> -->
          <div class="carousel-item-content container">
            <!-- <img v-if="item.type==='IMG'" :src="item.image" alt=""> -->
            <div style="height:250px;width:70%">

              <h1>{{item.title}}</h1>
              <p>{{item.content}}</p>

            </div>
            <el-button v-if="item.linkType!=='NO_URL'" @click="toNews(item)" size="medium"
              icon="el-icon-s-promotion" style="color: var(--color-primary)">查看详情
            </el-button>

          </div>
        </div>
      </transition-group>
      <!-- 切换的小按钮部分 -->
      <ul class="bannerBtn">
        <li v-for="(item,index) in carouselList" :key="index"
          :style="{ background: index===mark?'#ff7800':''}" @click='change(index)' class='aBtn'>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import { getSliders } from "@/api/navs";
export default {
  name: "HomeBanner",
  data() {
    return {
      mark: 0,
      carouselList: [
        // {
        //   id: "1",
        //   title: "FIT2CLOUD 飞致云位列中立型多云管理厂商第一名",
        //   content:
        //     "2020年6月1日，IDC最新发布的云系统和服务管理软件市场半年跟踪报告显示，2019年中国云系统和服务管理软件市场的市场规模达到了1.51亿美元，同比增长44.2%。",
        //   img: "",
        //   url: "",
        //   bg: "banner1.jpg",
        // },
        // {
        //   id: "2",
        //   title: "企业云管平台建设指南",
        //   content:
        //     "FIT2CLOUD 提出了“3+3 CMP 产品能力评估模型”，并总结出云管平台项目实施落地“七步法”",
        //   img: "",
        //   url: "",
        //   bg: "banner2.jpg",
        // },
        // {
        //   id: "3",
        //   title: "CloudExplorer 多云管理平台产品品牌启航",
        //   content:
        //     "2021年1月13日，FIT2CLOUD飞致云宣布正式启用多云管理平台软件新品牌——CloudExplorer，并且同步发布多云管理平台v3.0版本。自v3.0版本起，FIT2CLOUD创始产品“FIT2CLOUD多云管理平台”将正式更名为“CloudExplorer多云管理平台”。",
        //   img: "",
        //   url: "",
        //   bg: "banner3.jpg",
        // },
        // {
        //   id: "4",
        //   title: "",
        //   content: "",
        //   img: "banner1.png",
        //   url: "",
        // },
      ],
    };
  },
  created() {
    this.play();
    this.getSliders();
  },
  methods: {
    toNews(item) {
      if (item.linkType === "POST") {
        this.$router.push(`/news/${item.postId}`);
      } else if (item.linkType === "EXTERNAL") {
        window.open(item.url);
      }
    },
    // 轮播图
    getSliders() {
      getSliders()
        .then((response) => {
          this.carouselList = response || [];
        })
        .catch(() => {
          this.carouselList = [];
        });
    },

    // getBGStyle(val) {
    //   return val && require(`../../../assets/banner/${val}`);
    // },
    autoPlay() {
      if (this.mark < this.carouselList.length - 1) {
        this.mark++;
      } else {
        this.mark = 0;
      }
    },
    play() {
      this.timer = setInterval(this.autoPlay, 6000);
    },
    change(num) {
      this.mark = num;
      clearInterval(this.timer);
      this.play();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="scss">
.home-banner {
  color: #282828;
  background: url("~@/assets/banner/banner3.jpg") rgba(60, 72, 88, 0.6) center
    no-repeat;
  background-size: cover;
  .home-carousel {
    position: relative;
    height: 550px;
    line-height: 25px;
    .carousel-item {
      background: url("~@/assets/banner/banner3.jpg") #021c3a center no-repeat;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      h1 {
        margin: 20px 0;
        line-height: 32px;
      }
      img {
        display: block;
        object-fit: cover;
        width: 70%;
        height: 260px;
        margin-bottom: 10px;
      }
      .carousel-item-content {
        padding-top: 120px;
        position: relative;
      }
    }
    .bannerBtn {
      width: 200px;
      position: absolute;
      left: 50%;
      margin-left: -100px;
      bottom: 22px;
      text-align: center;
      li {
        margin: 0 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        background: rgba(255, 255, 255, 0.4);
      }
      .aBtn {
        transition: all 0.6s ease;
      }
    }
  }
  // 动画
  .img-enter-active,
  .img-leave-active {
    transition: all 2s;
  }

  .img-enter,
  .img-leave-to {
    opacity: 0;
  }

  .img-enter-to,
  .img-leave {
    opacity: 1;
  }
}
</style>
