<template>
  <div class="default-banner">
    <div class="bg-overlay"></div>
    <div class="container">
      <breadcrumb class="breadcrumb" :detailTitle="detailTitle"></breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DefaultBanner",
  computed: {
    ...mapGetters(["detailTitle"]),
  },
};
</script>

<style lang="scss" scoped>
.default-banner {
  position: relative;
  background: url("~@/assets/banner/banner4.png") #021c3a top center no-repeat;
  background-size: 100%;
  // right: 0;
  // left: 0;
  // top: 0;
  // z-index: 1;
  // transition: all 0.5s ease;
  min-height: 130px;
  box-shadow: 0 1px 2px 0 #e1e5ea;
  .breadcrumb {
    position: absolute;
    bottom: 0;
  }
}
</style>