// 根据实际需要修改
const getters = {
  detailTitle: state => state.app.detailTitle,  //面包屑需要的标题

  // 产品
  proDetail: state => state.products.proDetail,
  activeTab: state => state.products.activeTab,

  // 用户信息
  userInfo: state => state.user.userInfo,
}
export default getters
