<template>
  <div class="nav-tabs">
    <ul class="container">
      <li @click="activeTabHandle(item.id)"
        :class="active.toLowerCase()===item.id.toLowerCase() && 'active'"
        v-for="(item, index) in tabs" :key="index">
        <span class="title-cn">{{item.name}}</span>

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    EnterpriseTrial: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newValue) {
        if (newValue.params.tab) {
          this.activeTabHandle(newValue.params.tab);
        } else {
          this.active = "";
          this.$store.commit("products/SAVE_activeTab", "");
        }
      },
    },
  },
  mounted() {
    if (this.$route.params.tab) {
      this.activeTabHandle(this.$route.params.tab);
    }
  },
  methods: {
    activeTabHandle(id) {
      this.active = id;
      this.$store.commit("products/SAVE_activeTab", id.toLowerCase());
      if (this.$route.name === "ProductDownload") {
        this.$router.push(
          `/products/${this.$route.params.id}/${id.toLowerCase()}`
        );
      } else {
        window.history.pushState(
          {},
          "",
          `#/products/${this.$route.params.id}/${id.toLowerCase()}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tabs {
  background: rgba(10, 74, 113, 0.7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 1;
  color: #ffffff;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.16);
  // border-bottom: 1px solid #ebedef;
  li {
    // background: #1a1a1a;
    display: inline-block;
    width: 20%;
    text-align: center;
    padding: 10px 0;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    span {
      display: inline-block;
      padding: 3px 0 4px;
    }
    &:last-child {
      border-right: 0 !important;
    }
    &.active {
      color: var(--color-primary);
      background: #ffffff;
      // border-bottom: 3px solid var(--color-primary);;
    }
  }
}
</style>
