<template>
  <div class="main-wrapper">
    <keep-alive :include="caches">
      <router-view :key="key" />
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "AppMain",

  data() {
    return {
      caches: [],
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  beforeUpdate() {
    let cache = this.$route.meta?.cache;
    let name = this.$route.name;
    if (cache && name && !this.caches.includes(name)) {
      this.caches.push(name);
    }
  },
};
</script>
<style lang="scss" scoped>
// .main-wrapper {
//   box-sizing: border-box;
//   padding: 20px 10px;
// }
</style>
