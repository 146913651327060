<template>
  <div class="header-nav">
    <div class="container nav">
      <div class="app-logo">
        <img
          @click="$router.push('/')"
          src="@/assets/logo/logo-FIT2CLOUD.png"
          height="20"
          alt=""
        />
      </div>
      <nav class="primary-nav">
        <ul>
          <li>
            <a href="https://bbs.fit2cloud.com/" target="_blank"> 社区论坛</a>
          </li>
          <li>
            <el-dropdown placement="bottom" trigger="hover">
              <span style="color:#191818; font-size: 15px">
                开源产品<i class="icon el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="home-dropdown">
                <!-- 二级菜单 -->
                <div class="sub-nav">
                  <ul class="submenu new-submenu">
                    <li v-for="(item, index) in subNav" :key="index">
                      <a @click="toProduct(item)" target="_blank">
                        <div class="submenu-item">
                          <div
                            class="border rounded mr-3 text-center icon"
                            style="padding: 10px"
                          >
                            <img
                              :src="getImg(item.productId)"
                              class="l-dark"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div class="">
                            <p
                              style="color: #212529; font-size: 15px; font-weight: 500; margin: 0"
                            >
                              <b>{{ item.name }}</b>
                            </p>
                            <p class="subName">{{ item.productTitle }}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <!-- <el-row class="sub-nav-row">
                    <el-col :span="9">
                      <ul class="subnav-root">
                        <li
                          @mouseenter="mouseEnter(item.productId)"
                          :class="subNavActive === item.productId && 'active'"
                          v-for="(item, index) in subNav"
                          :key="index"
                        >
                          <span>{{ item.name }}</span>
                          <p class="subName">{{ item.productTitle }}</p>
                        </li>
                      </ul>
                    </el-col>
                    <el-col :span="15">
                      <div class="subnav-child">
                        <transition name="el-fade-in-linear">
                          <ul v-show="subnavShow">
                            <li
                              @click="toPath(item.tabId)"
                              v-for="(item, index) in subNavChild"
                              :key="index"
                            >
                              <el-link
                                :disabled="disabled(item.tabId)"
                                :href="item.url"
                                target="_blank"
                                :underline="false"
                              >
                                <i class="icon el-icon-d-arrow-right"></i>
                                {{ item.tabName }}
                              </el-link>
                            </li>
                          </ul>
                        </transition>
                      </div>
                    </el-col>
                  </el-row> -->
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <a href="/bi" target="_blank"> 开源大屏</a>
          </li>
          <li>
            <a href="https://blog.fit2cloud.com/" target="_blank"> 技术博客</a>
          </li>
          <li>
            <a href="https://edu.fit2cloud.com" target="_blank"> 培训认证</a>
          </li>
          <li>
            <a href="https://kb.fit2cloud.com/" target="_blank"> 知识库</a>
          </li>
          <li>
            <a
              href="https://shop147690775.youzan.com/v2/showcase/homepage?alias=rSbln0AX4X&dc_ps=3458057887732083718.300001"
              target="_blank"
            >
              周边商城</a
            >
          </li>
          <li>
            <a href="https://space.bilibili.com/510493147" target="_blank">
              <i class="iconfont icon-bofang" style="margin-right:5px"></i
              >观看直播</a
            >
          </li>
          <li>
            <div v-if="isAuthenticated">
              <el-dropdown trigger="hover">
                <el-button
                  type="primary"
                  icon="el-icon-user"
                  circle
                ></el-button>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                  <el-dropdown-item @click.native="$router.push('/account')">
                    个人中心
                  </el-dropdown-item>
                  <el-dropdown-item divided @click.native="logout">
                    退出
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-if="!isAuthenticated">
              <el-button type="primary" size="medium" @click="login"
                >登录</el-button
              >
              <!-- <p class="register">没有账户？<a @click="register">立即创建</a></p> -->
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { listSubNavs } from "@/api/navs";
export default {
  name: "AppNav",
  components: {},
  data() {
    return {
      subNavActive: "",
      subNav: [],
      refresh: false,
      subnavShow: true
    };
  },
  computed: {
    ...mapGetters(["activeTab"]),
    subNavChild() {
      const attr = this.subNav.filter(
        item => this.subNavActive === item.productId
      );
      return attr[0]?.tabs || [];
    },
    isAuthenticated() {
      return Vue.$keycloak.authenticated;
    }
    // name() {
    //   return Vue.$keycloak.tokenParsed.name;
    // },
  },
  watch: {
    subNav: {
      immediate: true,
      handler: function(newValue) {
        if (newValue.length === 0) {
          this.getSubNav();
        }
      }
    }
  },
  created() {},
  methods: {
    toProduct(item) {
      this.$router.push(`/products/${item.productId}/information`);
    },
    getImg(name) {
      return name ? require(`@/assets/logo/${name}.png`) : "";
    },
    // 登录
    login() {
      Vue.$keycloak.login();
    },
    logout() {
      Vue.$keycloak.logout();
    },
    register() {
      Vue.$keycloak.register();
    },
    getSubNav() {
      listSubNavs()
        .then(response => {
          if (response && response.length > 0) {
            this.subNav = response;
            this.subNavActive = this.subNav[0].productId;
          } else {
            this.subNav = [];
            this.subNavActive = "";
          }
        })
        .catch(() => {
          this.subNav = [];
          this.subNavActive = "";
        });
    },
    mouseEnter(productId) {
      this.subnavShow = false;
      this.subNavActive = productId;
      setTimeout(() => {
        this.subnavShow = true;
      }, 300);
    },
    toPath(tabId) {
      if (tabId) {
        this.$router.push(`/products/${this.subNavActive}/${tabId}`);
      } else {
        this.navActive = "";
        return;
      }
    },
    disabled(tabId) {
      return (
        this.$route.params &&
        this.$route.params.id === String(this.subNavActive) &&
        this.activeTab === String(tabId).toLowerCase()
      );
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-nav {
  padding: 15px 0;
  color: #191818;
  .nav {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    .app-logo {
      cursor: pointer;
      margin-left: 5px;
      margin-top: 5px;
    }
    .primary-nav {
      // margin-left: 50px;
      li {
        cursor: pointer;
        display: inline-block;
        position: relative;
        padding: 0 15px;
        // font-weight: bold;
        font-size: 15px;
        &:after {
          content: "";
          // background: #fff;
          transition-duration: 0.4s;
          position: absolute;
          bottom: -10px;
          left: 20px;
          width: 33px;
          height: 3px;
        }
        &:hover {
          color: rgba(24, 24, 24, 0.5);
        }
      }
      li.active {
        &:after {
          background: #e00;
        }
      }
    }
  }
}
// 弹出菜单
.sub-nav {
  min-width: 300px;
  font-size: 15px;
  .sub-nav-row {
    background: rgba(255, 255, 255, 0.86);
  }
  .subnav-root {
    // padding: 15px 0;
    box-sizing: border-box;
    min-width: 100px;
    // height: 348px;
    background: rgba(255, 255, 255, 0.33);
    li {
      cursor: pointer;
      // border-bottom: 3px solid #ffffff;
      padding: 10px 10px;
      font-size: 16px;
      font-weight: bold;
    }
    li.active {
      background: rgba(218, 221, 224, 0.86);
      color: var(--color-primary);
      border-left: 2px solid var(--color-primary);
    }
    .subName {
      font-size: 12px;
      color: #9c9c9c;
      margin: 5px 0 0;
    }
  }
  .subnav-child {
    // background: rgba(218, 221, 224, 0.86);

    // border-left: 1px solid #ece9e9;
    padding: 15px;
    li {
      margin: 0px 15px 20px;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  .icon {
    margin-right: 10px;
  }
}
.home-dropdown {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.new-submenu {
  .icon {
    border: 1px solid #e9ecef !important;
    border-radius: 5px;
    margin-right: 15px;
    height: 22px;
  }
  .submenu-item {
    display: flex;
    margin: 8px 0;
    padding: 8px 16px;
    align-items: center;
    &:hover {
      background: #f3f4f6;
      .icon {
        background: #ffffff;
      }
      .subName {
        color: #004a71;
      }
    }
  }
  .subName {
    font-size: 12px;
    color: #3c4858;
    margin: 10px 0 0 0;
  }
}
</style>
