<template>
  <div class="page-banner">
    <!-- <div class="bg-overlay"></div> -->
    <div class="pro-header">
      <div class="container" style="position: relative;">
        <div class="pro-title">
          <h1 class="title-h1">{{proDetail&&proDetail.name}}</h1>
          <breadcrumb :detailTitle="proDetail&&proDetail.name"></breadcrumb>
        </div>
        <div class="download-wrapper" v-if="resourceList&&resourceList.length>0">
          <el-button class="left-download" type="primary" round
            @click="downloadVersion(resourceList[0].id)">下载</el-button>
          <el-dropdown trigger="click" placement="bottom" @command="downloadVersion">
            <el-button class="right-download" type="primary" round>{{resourceList[0].name}}
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" style="margin-top:0">
              <el-dropdown-item v-for="(item, index) in resourceList" :key="index"
                :command="item.id" icon="el-icon-paperclip">
                {{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <nav-tabs :tabs="tabs" style="margin-top:20px" />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavTabs from "@/components/nav-tabs";
export default {
  name: "ProDetailBanner",
  components: {
    NavTabs,
  },
  computed: {
    ...mapGetters(["proDetail"]),
    latestRelease() {
      return this.proDetail?.latestReleaseInfo?.name || "最新版";
    },
    resourceList() {
      return this.proDetail?.latestReleaseInfo?.resourceList || [];
    },
  },
  data() {
    return {
      tabs: [
        {
          id: "Information",
          name: "产品信息",
        },
        {
          id: "Getstarted",
          name: "在线安装",
        },
        // {
        //   id: "Enterprisetrial",
        //   name: "企业版试用",
        // },
        {
          id: "Documentation",
          name: "产品文档",
        },
        {
          id: "Downloads",
          name: "社区版下载",
        },
        {
          id: "Techexchange",
          name: "交流 & 贡献",
        },
      ],
    };
  },

  methods: {
    downloadVersion(resourceId) {
      const releaseId = this.proDetail?.latestRelease;
      this.$store.dispatch("products/asynDownloadResources", {
        releaseId,
        resourceId,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.page-banner {
  position: relative;
  background: url("~@/assets/banner/banner5.jpg") #021c3a top center
    no-repeat;
  background-size: cover;
  // color: #ffffff;
  min-height: 295px;
  .pro-header {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .pro-title {
    height: 120px;
    .title-h1 {
      font-size: 35px;
      margin-bottom: 10px;
    }
  }
  .download-wrapper {
    position: absolute;
    right: 100px;
    top: 30px;
    .left-download {
      font-size: 17px;
      padding: 13px 28px;
      border-radius: 27px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.47);
      background: rgba(10, 74, 113, 0.7);
      border: 1px solid #4b7c9d;
      border-right: 1px solid rgba(255, 255, 255, 0.47);
    }
    .right-download {
      font-size: 16px;
      padding: 13px 28px;
      border-radius: 27px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: rgba(10, 74, 113, 0.7);
      border: 1px solid #4b7c9d;
    }
    // .download {
    //   background: var(--color-primary);
    //   color: #ffffff;
    //   box-shadow: 0 0 3px rgb(60, 72, 88, 0.15);
    //   padding: 10px 70px;
    //   border-radius: 50px;
    //   cursor: pointer;
    //   &:hover {
    //     background: #336e8d;
    //   }
    //   .icon {
    //     position: absolute;
    //     right: 15px;
    //     top: 16px;
    //     font-size: 38px;
    //   }
    //   .text {
    //     text-align: center;
    //     line-height: 25px;
    //     p {
    //       padding: 0;
    //       margin: 0;
    //     }
    //     .tit {
    //       font-size: 18px;
    //       font-weight: bold;
    //     }
    //   }
    // }
  }
}
</style>
