import Vue from "vue"
import Router from "vue-router"

// 修复路由变更后报错的问题
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(Router)

import Layout from "@/components/layout";

/**
 * 新增meta属性区分headter样式
 * meta : {
    type: proDetail; 产品详情页面，头部特殊，例如ProductDetail，需要有一个属性 title: "产品详情"
  }
 * 详情页面的都要有动态id和title。否则没有面包屑
 */

export const constantRoutes = [{
    path: "/register",
    component: () => import("@/business/register"),
    name: "Register",

  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [{
      path: "home",
      component: () => import("@/business/home"),
      name: "Home",
      meta: {
        title: "首页"
      }
    }]
  },
  {
    path: "/products",
    component: Layout,
    // meta: {
    //   title: "产品"
    // },
    children: [
      // {
      //   path: "/products",
      //   component: () => import("@/business/products/ProductList"),
      //   name: "ProductList",
      // },
      {
        path: '/products/:id/:tab',
        component: () => import("@/business/products/ProductDetail/index.vue"),
        name: 'ProductDetail',
        meta: {
          type: "proDetail",
          title: "产品详情"
        },
      },
    ]
  },
  {
    path: "/download",
    component: Layout,
    // meta: {
    //   title: "产品"
    // },
    children: [
      {
        path: '/download/:id/:version',
        component: () => import("@/business/products/ProductDownload/index.vue"),
        name: 'ProductDownload',
        meta: {
          type: "download",
          title: "版本详情"
        },
      },
    ]
  },
  {
    path: "/news",
    component: Layout,
    meta: {
      title: "新闻"
    },
    children: [{
        path: "/news",
        component: () => import("@/business/news/NewsList"),
        name: "NewsList",
      },
      {
        path: '/news/:id',
        component: () => import("@/business/news/NewsDetail"),
        name: 'NewsDetail',
        meta: {
          title: "详情"
        },
      },
    ]
  },
  {
    path: "/account",
    component: Layout,
    meta: {
      title: "个人中心"
    },
    children: [{
        path: "/account",
        component: () => import("@/business/accountCenter"),
        name: "accountCenter",
        meta: {
          keycloak: true
        },
      },
    ]
  }
]


const createRouter = () => new Router({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
router.beforeEach((to, from, next) => {
  if(to.meta?.keycloak){
    if(Vue.$keycloak.authenticated){
      next()
    } else {
      router.push('/')
    }

  }
  // console.log(Vue.$keycloak.authenticated);

  next()
})
// router.afterEach((to) => {
//   if (!to.name) {
//     router.push(to.path.split("&")[0])
//   }
// })
export default router
