const state = {

  detailTitle: ""
}

const mutations = {
  DETAIL_TITLE : (state, detailTitle) => {
    state.detailTitle = detailTitle
  },
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}