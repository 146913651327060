<template>
  <mavon-editor class="md" :value="value" :subfield="false" :defaultOpen="'preview'"
    :toolbarsFlag="false" :editable="false" :scrollStyle="true" :boxShadow="false"
    :transition="false" previewBackground="#ffffff">

  </mavon-editor>
</template>

<script>
export default {
  name: "Markdown",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
// markdown
.md {
  border: none !important;
  .v-show-content {
    padding: 0 !important;
}
}

</style>
