
import Vue from "vue";
const state = {
  userInfo: null,
}

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
}

const actions = {
  // get user info
  getKeycloakInfo({commit}) {
    return new Promise((resolve, reject) => {
      if (!Vue.prototype.$keycloak) {
        reject('keycloak not init')
      }

      if (!Vue.prototype.$keycloak.authenticated) {
        reject('Verification failed, please Login again.')
      }
      // you can also use the method loadUserProfile() to get user attributes
      Vue.$keycloak.loadUserProfile().then(profile => {
        commit('SET_USERINFO', profile)
        resolve(profile)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}