import Vue from "vue"
import "@/styles/index.scss"
import {
  Pagination,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Icon,
  Row,
  Col,
  Card,
  Carousel,
  CarouselItem,
  Link,
  Loading,
  Skeleton,
  Scrollbar,
  SkeletonItem,
  Avatar,
  Backtop,
  Table,
  TableColumn,
  Popover,
  Dialog,
  Radio,
  RadioGroup
} from 'element-ui';

import App from "./App.vue"
import router from "./router"
import store from "./store"
import plugins from "./plugins";
import './components'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import moment from 'moment'
// use
Vue.use(mavonEditor)
Vue.use(plugins);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Link);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(SkeletonItem);
Vue.use(Scrollbar);
Vue.use(Avatar);
Vue.use(Backtop);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading.directive);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(RadioGroup);
//  全局过滤器 时间戳
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (dataStr) {
    return moment(dataStr).format(pattern)
  } else {
    return dataStr
  }
})
/* ions */
import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false
// console.log(Vue.$keycloak)

let keycloak = Vue.$keycloak
keycloak
  .init({
    onLoad: 'check-sso',
    checkLoginIframe: false
  })
  .then((auth) => {
    // console.log(auth)
    new Vue({
      el: "#app",
      router,
      store,
      render: h => h(App),
    })

    // Token Refresh
    if (auth) {
      setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
            // console.log('Token refreshed: ' + refreshed);
          } else {
            // console.warn('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
        }).catch(() => {
          // console.error('Failed to refresh token');
        });

      }, 6000)
    }
  })
  .catch(() => {
    new Vue({
      el: "#app",
      router,
      store,
      render: h => h(App),
    })
  })
