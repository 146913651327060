<template>
  <el-scrollbar class="app-wrapper" style="height:100%;" ref="scroll">
    <el-backtop target=".el-scrollbar__wrap" :bottom="200"></el-backtop>
    <!-- <div>  -->
    <div class="header">
      <app-header></app-header>
      <home-banner v-if="isHome" />
      <pro-detail-banner v-else-if="isProDetail" />
      <default-banner v-else />
    </div>
    <app-main class="main" :class="!isHome && 'container'"
      :style="!isHome && 'padding:20px 10px;'" />
    <app-footer />
    <!-- </div> -->
  </el-scrollbar>
</template>

<script>
import AppHeader from "./AppHeader";
import AppMain from "./AppMain";
import AppFooter from "./AppFooter";
import HomeBanner from "./banner/HomeBanner";
import DefaultBanner from "./banner/DefaultBanner";
import ProDetailBanner from "./banner/ProDetailBanner";
export default {
  name: "Home",
  components: {
    AppHeader,
    AppMain,
    HomeBanner,
    DefaultBanner,
    ProDetailBanner,
    AppFooter,
  },
  computed: {
    isHome() {
      return this.$route.path === "/home";
    },
    isProDetail() {
      return (
        this.$route.meta &&
        (this.$route.meta.type === "proDetail" ||
          this.$route.meta.type === "download")
      );
    },
  },
  data() {
    return {
      detail: null,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  .main {
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .header {
    flex: 0 0 auto;
  }
}
</style>
