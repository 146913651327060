import {downloadResources} from "@/api/download";
import {downloadByURL} from "@/utils/utils"
import Vue from "vue";

const state = {
  proDetail: null,
  activeTab: ""
}

const mutations = {
  PRO_DETAIL: (state, proDetail) => {
    state.proDetail = proDetail
  },
  SAVE_activeTab: (state, activeTab) => {
    state.activeTab = activeTab
  }
}

const actions = {
  // 版本下载
  asynDownloadResources({state}, data) {
    if (Vue.$keycloak.authenticated) {
      return new Promise((resolve, reject) => {
        downloadResources(state.proDetail?.id, data.releaseId, data.resourceId).then(res => {
          downloadByURL(res.tempUrl)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    } else {
      Vue.$keycloak.login();
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
