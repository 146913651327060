export function download(res, name) {
  const blob = new Blob([res], {
    type: 'application/octet-stream'
  })
  const a = document.createElement('a')
  a.setAttribute('href', window.URL.createObjectURL(blob))
  a.setAttribute('download', name)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function downloadByURL(url, name) {
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', name)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

// // 下划线转换驼峰
// export function toHump(name) {
//   /* eslint-disable no-useless-escape */
//   return name.replace(/\_(\w)/g, function (all, letter) {
//     return letter.toUpperCase();
//   });
// }
// // 驼峰转换下划线
// export function toLine(name) {
//   return name.replace(/([A-Z])/g, "_$1").toLowerCase();
// }
