<template>
  <el-breadcrumb class="app-breadcrumb" separator-class="el-icon-arrow-right">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span v-if="item.redirect==='noRedirect'||index==levelList.length-1"
          class="no-redirect">{{ getTitle(item.meta) }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      levelList: null,
    };
  },
  props: {
    detailTitle: String,
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (route) {
        if (route.path.startsWith("/redirect/")) {
          return;
        }
        this.getBreadcrumb();
      },
    },
  },
  // mounted() {
  //   this.getBreadcrumb();
  // },
  methods: {
    getTitle(meta) {
      if (this.$route.params?.id) {
        return this.detailTitle;
      } else {
        return meta.title;
      }
    },
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched;

      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      }
      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === "Home".toLocaleLowerCase();
    },
    pathCompile(path) {
      const pathToRegexp = require("path-to-regexp");
      const { params } = this.$route;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
  },
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;
  a {
    color: #0a4a71;
    &:hover {
      text-decoration: underline;
    }
  }

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
